import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import './tailwind.scss';
import './App.css';
const StartChatGPT = lazy(() => import("./lp"));
const Pricing = lazy(() => import("./pricing"));
const Register = lazy(() => import("./register"));
const Login = lazy(() => import("./login"));
const Forgot = lazy(() => import("./forgot"));
const Reset = lazy(() => import("./resetpass"));
const Disclaimer = lazy(() => import("./footer/disclaimer"));

const queryClient = new QueryClient();

function App() {

  return (

    <Router>
      <div>
        <QueryClientProvider client={queryClient}>
        <Suspense fallback={null}>
            <div className="md:min-h-[90vh]">
              <Routes>
              <Route path="/" element={<StartChatGPT />} />
              <Route path="/chat-app" element={<StartChatGPT />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/resetpass" element={<Reset />} />
              </Routes>
            </div>
            <Disclaimer/>
          </Suspense>
        </QueryClientProvider>
        <div className="loader-container"><div className="overlay"></div><div className="lds-ai"><div></div><div></div><div></div></div></div>
      </div>
    </Router>
  );
}
export default App;